import { Injectable, Injector } from '@angular/core';

import { BehaviorSubject, Subject } from 'rxjs';

import { debounceTime, switchMap } from 'rxjs/operators';
import { DeviceModel } from '../../models/device-model';
import { BackendBaseService } from '../backend-base/backend-base.service';
import { PaginatedState } from '../../directives/sortable-header/paginated-state';
import { SortColumn, SortDirection } from '../../directives/sortable-header/sortable-header.directive';
import { nameof } from '../../nameof/nameof.operator';

interface SearchResult {
    devices: DeviceModel[];
    total: number;
}

const serviceBasePath = '/device';

@Injectable({
    providedIn: 'root',
})
export class DeviceService extends BackendBaseService<DeviceModel> {
    private searchSubj = new Subject<void>();
    private devices = new BehaviorSubject<DeviceModel[]>([]);
    private total = new BehaviorSubject<number>(0);

    private state: PaginatedState<DeviceModel> = {
        page: 1,
        pageSize: 10,
        searchTerm: '',
        searchColumns: [nameof<DeviceModel>('name'), nameof<DeviceModel>('androidId')],
        sortColumn: 'name',
        sortDirection: 'ASC',
    };

    constructor(injector: Injector) {
        super(injector, serviceBasePath);

        this.searchSubj
            .pipe(
                debounceTime(200),
                switchMap(() => this.search()),
            )
            .subscribe();

        this.search();
    }

    get devices$() {
        return this.devices;
    }
    get total$() {
        return this.total.asObservable();
    }
    get page() {
        return this.state.page;
    }
    get pageSize() {
        return this.state.pageSize;
    }
    get searchTerm() {
        return this.state.searchTerm;
    }

    set page(page: number) {
        this.set({ page });
    }
    set pageSize(pageSize: number) {
        this.set({ pageSize });
    }
    set searchTerm(searchTerm: string) {
        this.set({ searchTerm });
    }
    set sortColumn(sortColumn: SortColumn<DeviceModel>) {
        this.set({ sortColumn });
    }
    set sortDirection(sortDirection: SortDirection) {
        this.set({ sortDirection });
    }

    async refresh(autoHideLoader = true) {
        await this.search(autoHideLoader);
    }

    private set(patch: Partial<PaginatedState<DeviceModel>>) {
        Object.assign(this.state, patch);
        this.searchSubj.next();
    }

    public async search(autoHideLoader = true): Promise<SearchResult> {
        const response = await this.paginatedList(this.state, [{ key: 'join', value: 'user' }]);

        if (autoHideLoader) {
            this.loaderService.hide();
        }

        const result = {
            devices: response?.data ?? [],
            total: response?.total ?? 0,
        };

        this.devices.next(result.devices);
        this.total.next(result.total);

        return result;
    }
}
