export enum ControlType {
    WOL = 'WOL',
    CAMERA = 'CAMERA',
    RELAY_DENKOVI = 'RELAY_DENKOVI',
    RELAY_HID = 'RELAY_HID',
}

export interface ControlModel {
    id: number;
    name: string;
    type: ControlType;
    accessString: string;
    order: number;
}
