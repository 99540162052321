<div class="conainer">
    <form>
        <app-new-device-dialog id="new"></app-new-device-dialog>
        <button type="button" id="refresh" (click)="refresh()" class="btn btn-sm btn-primary">
            <fa-icon [icon]="faSync"></fa-icon>
            Frissítés
        </button>

        <table class="table table-striped">
            <thead>
                <tr>
                    <th scope="col">Azon.</th>
                    <th scope="col" sortable="name" (sort)="onSort($event)">Név</th>
                    <th scope="col" sortable="androidId" (sort)="onSort($event)">Android ID</th>
                    <th scope="col" sortable="userName" (sort)="onSort($event)">Felhasználó</th>
                    <th scope="col"></th>
                </tr>
                <tr>
                    <th colspan="5">
                        <input
                            class="form-control ml-2 search-box"
                            type="text"
                            name="searchTerm"
                            placeholder="Keresés minden mezőben ..."
                            [(ngModel)]="deviceService.searchTerm"
                        />
                    </th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let device of deviceService.devices$ | async">
                    <th scope="row">{{ device.id }}</th>
                    <td>
                        <ngb-highlight [result]="device.name" [term]="deviceService.searchTerm"></ngb-highlight>
                    </td>
                    <td>
                        <ngb-highlight [result]="device.androidId" [term]="deviceService.searchTerm"></ngb-highlight>
                    </td>
                    <td>
                        <ngb-highlight
                            [result]="(device.user?.lastName ?? '') + ' ' + (device.user?.firstName ?? '')"
                            [term]="deviceService.searchTerm"
                        ></ngb-highlight>
                    </td>
                    <td>
                        <div class="edit-delete">
                            <app-new-device-dialog [isEdit]="true" [device]="device"></app-new-device-dialog>
                            <button type="button" (click)="delete(device.id)" class="btn btn-sm btn-danger delete">
                                <span>
                                    <fa-icon [icon]="faTrash"></fa-icon>
                                </span>
                            </button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>

        <div class="d-flex justify-content-between p-2">
            <ngb-pagination
                [collectionSize]="(deviceService.total$ | async)!"
                [(page)]="deviceService.page"
                [pageSize]="deviceService.pageSize"
            >
            </ngb-pagination>

            <select class="custom-select" style="width: auto" name="pageSize" [(ngModel)]="deviceService.pageSize">
                <option [value]="10">10 elem oldalanként</option>
                <option [value]="50">50 elem oldalanként</option>
                <option [value]="100">100 elem oldalanként</option>
            </select>
        </div>
    </form>
</div>
