import { Location } from '@angular/common';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { RouteValues } from '../../app-routing.module';
import { PaginatedResponseState } from '../../directives/sortable-header/response-state';
import { PaginatedState } from '../../directives/sortable-header/paginated-state';
import { LoaderService } from '../loader/loader.service';

export abstract class BackendBaseService<T> {
    private backendBasePath = '/api';
    protected basePath = `${this.backendBasePath}${this.serviceBasePath}`;
    protected http: HttpClient;
    protected location: Location;
    protected router: Router;
    protected loaderService: LoaderService;

    constructor(injector: Injector, private serviceBasePath: string) {
        this.http = injector.get(HttpClient);
        this.location = injector.get(Location);
        this.router = injector.get(Router);
        this.loaderService = injector.get(LoaderService);
    }

    protected async callApi<X>(
        path: string,
        method: 'GET' | 'POST' | 'PUT' | 'DELETE',
        options?: { params: HttpParams; headers?: HttpHeaders },
        body?: X,
    ): Promise<X> {
        if (!options) {
            options = { params: new HttpParams() };
        }
        if (!options.headers) {
            options.headers = new HttpHeaders();
        }

        options.headers = options.headers.append('Authorization', `Bearer ${localStorage.getItem('token')}`);

        let req: Observable<X>;
        switch (method) {
            case 'GET':
                req = this.http.get<X>(path, options);
                break;
            case 'POST':
                req = this.http.post<X>(path, body, options);
                break;
            case 'PUT':
                req = this.http.put<X>(path, body, options);
                break;
            case 'DELETE':
                req = this.http.delete<X>(path, options);
                break;
        }

        try {
            this.loaderService.show();
            return await req.toPromise();
        } catch (e) {
            if (e instanceof HttpErrorResponse && e.status === 401) {
                this.location.replaceState('/'); // clears browser history so they can't navigate with back button
                localStorage.removeItem('token');
                this.router.navigate([RouteValues.LOGIN]);
                this.loaderService.hide();
            } else {
                throw e;
            }
        }
    }

    async list(query: { key: string; value: string }[] | undefined = undefined): Promise<T[]> {
        let params = new HttpParams();
        query?.forEach((q) => (params = params.append(q.key, q.value)));

        return this.callApi<T[]>(`${this.basePath}`, 'GET', { params });
    }

    paginatedList(
        state: PaginatedState<T>,
        query: { key: string; value: string }[] | undefined = undefined,
    ): Promise<PaginatedResponseState<T[]>> {
        let params = new HttpParams();
        params = params.append('page', state.page);
        params = params.append('limit', state.pageSize);
        params = params.append('offset', (state.page - 1) * state.pageSize);

        query?.forEach((q) => (params = params.append(q.key, q.value)));

        if (state.searchTerm !== '') {
            for (let attr of state.searchColumns) {
                params = params.append('or', `${attr}||$cont||${state.searchTerm}`);
            }
        }

        if (state.sortColumn !== '' && state.sortDirection) {
            params = params.append('sort', `${state.sortColumn},${state.sortDirection}`);
        }

        return this.callApi<PaginatedResponseState<T[]>>(`${this.basePath}`, 'GET', { params });
    }

    delay(t: number) {
        return new Promise((resolve) => {
            setTimeout(resolve, t);
        });
    }

    async save(entity: T): Promise<T> {
        return this.callApi<T>(`${this.basePath}`, 'POST', undefined, entity);
    }

    async delete(id: number): Promise<void> {
        await this.callApi(`${this.basePath}/${id}`, 'DELETE');
    }
}
