import { Component, Input, OnInit } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ControlModel } from '../../../models/control-model';
import { DeviceModel } from '../../../models/device-model';
import { ControlService } from '../../../services/control/control.service';
import { DeviceService } from '../../../services/device/device.service';
import { LoaderService } from '../../../services/loader/loader.service';
import { UserService } from '../../../services/user/user.service';

@Component({
    selector: 'app-control-usage-dialog',
    templateUrl: './control-usage-dialog.component.html',
    styleUrls: ['./control-usage-dialog.component.css'],
})
export class ControlUsageDialogComponent implements OnInit {
    closeResult = '';

    @Input()
    control: ControlModel;

    devices: Observable<Partial<DeviceModel>[]>;

    device: DeviceModel;

    constructor(
        private modalService: NgbModal,
        public controlService: ControlService,
        public deviceService: DeviceService,
        public userService: UserService,
        public loaderService: LoaderService,
    ) {}

    ngOnInit(): void {}

    async open(content) {
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
            (result) => {
                this.closeResult = `Closed with: ${result}`;
            },
            (reason) => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            },
        );

        this.devices = this.deviceService.devices$.pipe(
            map((dd) => dd.filter((dd) => dd.user.guid === this.userService.loggedInUser.value.guid)),
        );
        this.device = this.deviceService.devices$.value[0];
    }

    private getDismissReason(reason: ModalDismissReasons): string {
        console.log(reason);
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    async useControl() {
        await this.controlService.useControl(this.control.id, this.device.androidId);

        this.modalService.dismissAll();
    }
}
