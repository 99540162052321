import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { PaginatedState } from '../../directives/sortable-header/paginated-state';
import { SortColumn, SortDirection } from '../../directives/sortable-header/sortable-header.directive';
import { HistoryModel } from '../../models/history-model';
import { nameof } from '../../nameof/nameof.operator';
import { BackendBaseService } from '../backend-base/backend-base.service';

interface SearchResult {
    histories: HistoryModel[];
    total: number;
}

const serviceBasePath = '/history';

@Injectable({
    providedIn: 'root',
})
export class HistoryService extends BackendBaseService<HistoryModel> {
    private searchSubj = new Subject<void>();
    private histories = new BehaviorSubject<HistoryModel[]>([]);
    private total = new BehaviorSubject<number>(0);

    private state: PaginatedState<HistoryModel> = {
        page: 1,
        pageSize: 10,
        searchTerm: '',
        searchColumns: [
            nameof<HistoryModel>('controlName'),
            nameof<HistoryModel>('actionName'),
            nameof<HistoryModel>('deviceName'),
            nameof<HistoryModel>('userFirstName'),
            nameof<HistoryModel>('userLastName'),
        ],
        sortColumn: 'createdAt',
        sortDirection: 'DESC',
    };

    constructor(injector: Injector) {
        super(injector, serviceBasePath);

        this.searchSubj
            .pipe(
                debounceTime(200),
                switchMap(() => this.search()),
            )
            .subscribe();

        this.search();
    }

    get histories$() {
        return this.histories.asObservable();
    }
    get total$() {
        return this.total.asObservable();
    }
    get page() {
        return this.state.page;
    }
    get pageSize() {
        return this.state.pageSize;
    }
    get searchTerm() {
        return this.state.searchTerm;
    }

    set page(page: number) {
        this.set({ page });
    }
    set pageSize(pageSize: number) {
        this.set({ pageSize });
    }
    set searchTerm(searchTerm: string) {
        this.set({ searchTerm });
    }
    set sortColumn(sortColumn: SortColumn<HistoryModel>) {
        this.set({ sortColumn });
    }
    set sortDirection(sortDirection: SortDirection) {
        this.set({ sortDirection });
    }

    async refresh(autoHideLoader = true) {
        await this.search(autoHideLoader);
    }

    private set(patch: Partial<PaginatedState<HistoryModel>>) {
        Object.assign(this.state, patch);
        this.searchSubj.next();
    }

    public async search(autoHideLoader = true): Promise<SearchResult> {
        const response = await this.paginatedList(this.state);

        if (autoHideLoader) {
            this.loaderService.hide();
        }

        const result = {
            histories: response?.data ?? [],
            total: response?.total ?? 0,
        };

        this.histories.next(result.histories);
        this.total.next(result.total);

        return result;
    }
}
