import { HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { PaginatedState } from '../../directives/sortable-header/paginated-state';
import { SortColumn, SortDirection } from '../../directives/sortable-header/sortable-header.directive';
import { ControlModel } from '../../models/control-model';
import { nameof } from '../../nameof/nameof.operator';
import { BackendBaseService } from '../backend-base/backend-base.service';

interface SearchResult {
    controls: ControlModel[];
    total: number;
}

const serviceBasePath = '/control';

@Injectable({
    providedIn: 'root',
})
export class ControlService extends BackendBaseService<ControlModel> {
    private searchSubj = new Subject<void>();
    private controls = new BehaviorSubject<ControlModel[]>([]);
    private total = new BehaviorSubject<number>(0);

    private state: PaginatedState<ControlModel> = {
        page: 1,
        pageSize: 10,
        searchTerm: '',
        searchColumns: [nameof<ControlModel>('name'), nameof<ControlModel>('accessString')],
        sortColumn: 'order',
        sortDirection: 'ASC',
    };

    constructor(injector: Injector) {
        super(injector, serviceBasePath);

        this.searchSubj
            .pipe(
                debounceTime(200),
                switchMap(() => this.search()),
            )
            .subscribe();

        this.search();
    }

    get controls$() {
        return this.controls.asObservable();
    }
    get total$() {
        return this.total.asObservable();
    }
    get page() {
        return this.state.page;
    }
    get pageSize() {
        return this.state.pageSize;
    }
    get searchTerm() {
        return this.state.searchTerm;
    }

    set page(page: number) {
        this.set({ page });
    }
    set pageSize(pageSize: number) {
        this.set({ pageSize });
    }
    set searchTerm(searchTerm: string) {
        this.set({ searchTerm });
    }
    set sortColumn(sortColumn: SortColumn<ControlModel>) {
        this.set({ sortColumn });
    }
    set sortDirection(sortDirection: SortDirection) {
        this.set({ sortDirection });
    }

    async refresh(autoHideLoader = true) {
        await this.search(autoHideLoader);
    }

    private set(patch: Partial<PaginatedState<ControlModel>>) {
        Object.assign(this.state, patch);
        this.searchSubj.next();
    }

    public async search(autoHideLoader = true): Promise<SearchResult> {
        const response = await this.paginatedList(this.state);

        if (autoHideLoader) {
            this.loaderService.hide();
        }

        const result = {
            controls: response?.data ?? [],
            total: response?.total ?? 0,
        };

        this.controls.next(result.controls);
        this.total.next(result.total);

        return result;
    }

    public async useControl(id: number, androidId: string): Promise<void> {
        let params = new HttpParams();
        params = params.append('androidId', androidId);
        await this.callApi(`${this.basePath}/${id}/use`, 'POST', { params });
        this.loaderService.hide();
    }
}
