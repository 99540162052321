<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Új felhasználó hozzáadás</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form (ngSubmit)="save()">
            <div class="form-group">
                <label for="name">Vezetéknév</label>
                <input [(ngModel)]="user.lastName" name="user.lastName" id="lastName" class="form-control" />
            </div>
            <div class="form-group">
                <label for="name">Keresztnév</label>
                <input [(ngModel)]="user.firstName" name="user.firstName" id="firstName" class="form-control" />
            </div>
            <div class="form-group">
                <label for="email">Email</label>
                <input [(ngModel)]="user.email" id="email" name="user.email" class="form-control" />
            </div>
            <button type="submit" class="btn btn-success save">Mentés</button>
        </form>
    </div>
</ng-template>

<button class="btn btn-sm btn-success" (click)="open($event, content)">
    <fa-icon *ngIf="isEdit" [icon]="faPencilAlt"></fa-icon>
    <fa-icon *ngIf="!isEdit" [icon]="faPlus"></fa-icon>
    <span *ngIf="!isEdit"> Új felhasználó</span>
</button>
