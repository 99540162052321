<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Új vezérlő hozzáadás</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form (ngSubmit)="save()">
            <div class="form-group">
                <label for="name">Név</label>
                <input [(ngModel)]="control.name" id="name" name="control.name" class="form-control" />
            </div>
            <div class="form-group">
                <label for="type">Típus</label>
                <select [(ngModel)]="control.type" id="type" name="control.type" class="form-control">
                    <option *ngFor="let type of controlTypes" [value]="type">
                        {{ type }}
                    </option>
                </select>
            </div>
            <div class="form-group">
                <label for="type">Hozzáférési kód</label>
                <input [(ngModel)]="control.accessString" id="accessString" name="accessString" class="form-control" />
            </div>
            <button type="submit" class="btn btn-success save">Mentés</button>
        </form>
    </div>
</ng-template>

<button class="btn btn-sm btn-success" (click)="open(content)">
    <fa-icon *ngIf="isEdit" [icon]="faPencilAlt"></fa-icon>
    <fa-icon *ngIf="!isEdit" [icon]="faPlus"></fa-icon>
    <span *ngIf="!isEdit"> Új vezérlő</span>
</button>
