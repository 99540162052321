import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RouteValues } from '../../app-routing.module';

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.css'],
})
export class NotFoundComponent implements OnInit {
    constructor(private router: Router) {}

    ngOnInit(): void {}

    public backToHome(): void {
        this.router.navigate([RouteValues.HOME]);
    }
}
