import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import jwtDecode from 'jwt-decode';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RouteValues } from '../../app-routing.module';
import { UserService } from '../../services/user/user.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent implements OnInit {
    loggedInUserEmail$: Observable<string>;

    constructor(private router: Router, public userService: UserService) {}

    ngOnInit(): void {
        const token = localStorage.getItem('token');

        if (token === null) {
            this.router.navigate([RouteValues.LOGIN]);
        }

        this.loggedInUserEmail$ = this.userService.loggedInUser.pipe(map((p) => p.email));
        this.parseToken();
    }

    public logout(): void {
        localStorage.removeItem('token');
        this.router.navigate([RouteValues.LOGIN]);
    }

    parseToken() {
        const token = localStorage.getItem('token');

        const decodedToken = jwtDecode(token) as any;
        this.userService.loggedInUser.next({
            guid: decodedToken.sub,
            email: decodedToken.email,
            firstName: decodedToken.firstName,
            lastName: decodedToken.lastName,
            profileImageUrl: decodedToken.profileImageUrl,
        });
    }
}
