import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, QueryList, ViewChildren } from '@angular/core';
import { faChevronDown, faChevronRight, faSync, faTrash } from '@fortawesome/free-solid-svg-icons';
import { SortableHeader, SortEvent } from '../../directives/sortable-header/sortable-header.directive';
import { UserModel } from '../../models/user-model';
import { DeviceService } from '../../services/device/device.service';
import { LoaderService } from '../../services/loader/loader.service';
import { PermissionService } from '../../services/permission/permission.service';
import { UserService } from '../../services/user/user.service';

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.css'],
})
export class UserComponent implements OnInit {
    faSync = faSync;
    faTrash = faTrash;
    faChevronRight = faChevronRight;
    faChevronDown = faChevronDown;
    notCollapsed = [];

    @ViewChildren(SortableHeader)
    headers: QueryList<SortableHeader<UserModel>>;

    constructor(
        public userService: UserService,
        public loaderService: LoaderService,
        public deviceService: DeviceService,
        public permissionService: PermissionService,
        @Inject(DOCUMENT) private doc: Document,
    ) {}

    ngOnInit(): void {}

    onSort({ column, direction }: SortEvent<UserModel>) {
        // resetting other headers
        this.headers.forEach((header) => {
            if (header.sortable !== column) {
                header.direction = '';
            }
        });

        this.userService.sortColumn = column;
        this.userService.sortDirection = direction;
    }

    async delete(event: Event, id: number) {
        event.stopPropagation();
        await this.userService.delete(id);

        await this.refresh();
    }

    async refresh() {
        await this.userService.refresh(false);
        await this.deviceService.refresh(false);
        await this.permissionService.refresh();
    }

    rowClick(rowIndex: number) {
        const right = this.doc.getElementById('chevronRight' + rowIndex).style.display;
        const down = this.doc.getElementById('chevronDown' + rowIndex).style.display;
        this.doc.getElementById('chevronDown' + rowIndex).style.display = right;
        this.doc.getElementById('chevronRight' + rowIndex).style.display = down;
    }
}
