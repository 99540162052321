import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-privacy-policy',
    templateUrl: './privacy-policy.component.html',
    styleUrls: ['./privacy-policy.component.css'],
})
export class PrivacyPolicyComponent implements OnInit {
    // https://www.termsfeed.com/live/5692a78b-6e6b-4a4c-953f-2b21012caa45
    constructor() {}

    ngOnInit(): void {}
}
