import { Component, Input, OnInit } from '@angular/core';
import { faPencilAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserModel } from '../../../models/user-model';
import { LoaderService } from '../../../services/loader/loader.service';
import { PermissionService } from '../../../services/permission/permission.service';
import { UserService } from '../../../services/user/user.service';

@Component({
    selector: 'app-new-user-dialog',
    templateUrl: './new-user-dialog.component.html',
    styleUrls: ['./new-user-dialog.component.css'],
})
export class NewUserDialogComponent implements OnInit {
    closeResult = '';
    faPlus = faPlus;
    faPencilAlt = faPencilAlt;

    @Input()
    isEdit: boolean = false;

    @Input()
    user: UserModel = {
        id: undefined,
        guid: undefined,
        firstName: '',
        lastName: '',
        email: '',
        devices: [],
        permissions: [],
    };

    constructor(
        private modalService: NgbModal,
        public loaderService: LoaderService,
        public userService: UserService,
        public permissionService: PermissionService,
    ) {}

    ngOnInit(): void {}

    open(event: Event, content) {
        event.stopPropagation();
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
            (result) => {
                this.closeResult = `Closed with: ${result}`;
            },
            (reason) => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            },
        );
    }

    private getDismissReason(reason: any): string {
        console.log(reason);
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    async save() {
        const userWithoutCircularDependency: UserModel = {
            ...this.user,
            devices: this.user.devices.map((d) => ({ ...d, user: undefined })),
        };
        await this.userService.save(userWithoutCircularDependency);
        this.modalService.dismissAll();

        await this.userService.refresh(false);
        await this.permissionService.refresh();
    }
}
