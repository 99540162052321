<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Vezérlés</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form (ngSubmit)="useControl()">
            <div class="form-group">
                <label for="controlName">Vezérló neve</label>
                <input [(ngModel)]="control.name" id="controlName" name="control.name" class="form-control" disabled />
            </div>
            <div class="form-group">
                <label for="androidId">Vezérléshez használt eszköz</label>
                <select [(ngModel)]="device" id="device" name="device" class="form-control">
                    <option *ngFor="let d of devices | async" [value]="d">
                        {{ d.name }}
                    </option>
                </select>
            </div>
            <button type="submit" class="btn btn-primary control">Vezérlés</button>
        </form>
    </div>
</ng-template>

<button class="btn btn-sm btn-primary" (click)="open(content)">Vezérlés</button>
