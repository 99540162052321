import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { LoginComponent } from './components/login/login.component';
import { LoaderComponent } from './components/loader/loader.component';
import { LoaderOverlayComponent } from './components/loader-overlay/loader-overlay.component';
import { Overlay } from '@angular/cdk/overlay';
import { LoginCallbackComponent } from './components/login-callback/login-callback.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { DeviceComponent } from './components/device/device.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SortableHeader } from './directives/sortable-header/sortable-header.directive';
import { UserComponent } from './components/user/user.component';
import { NewDeviceDialogComponent } from './components/device/new-device-dialog/new-device-dialog.component';
import { NewUserDialogComponent } from './components/user/new-user-dialog/new-user-dialog.component';
import { UserDeviceComponent } from './components/user/user-device/user-device.component';
import { ControlComponent } from './components/control/control.component';
import { NewControlDialogComponent } from './components/control/new-control-dialog/new-control-dialog.component';
import { HistoryComponent } from './components/history/history.component';
import { UserPermissionComponent } from './components/user/user-permission/user-permission.component';
import { ControlUsageDialogComponent } from './components/control/control-usage-dialog/control-usage-dialog.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        LoaderComponent,
        LoaderOverlayComponent,
        LoginCallbackComponent,
        NotFoundComponent,
        DeviceComponent,
        NavbarComponent,
        SortableHeader,
        UserComponent,
        NewDeviceDialogComponent,
        NewUserDialogComponent,
        UserDeviceComponent,
        ControlComponent,
        NewControlDialogComponent,
        HistoryComponent,
        UserPermissionComponent,
        ControlUsageDialogComponent,
        PrivacyPolicyComponent,
    ],
    imports: [BrowserModule, HttpClientModule, AppRoutingModule, FontAwesomeModule, FormsModule, NgbModule],
    providers: [Overlay],
    bootstrap: [AppComponent],
})
export class AppModule {}
