<div class="login-form">
    <h2 class="text-center">Bejelentkezés</h2>
    <div class="text-center social-btn">
        <form ngNoForm [action]="url">
            <button class="btn login-button">
                <img
                    alt="Google"
                    title="Sign in with Google"
                    src="assets/icons/google.png"
                    loading="lazy"
                    width="32px"
                />
                Google
            </button>
        </form>
    </div>
</div>
