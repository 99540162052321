<div class="conainer">
    <form>
        <app-new-control-dialog id="new"></app-new-control-dialog>
        <button type="button" id="refresh" (click)="refresh()" class="btn btn-sm btn-primary">
            <fa-icon [icon]="faSync"></fa-icon>
            Frissítés
        </button>

        <table class="table table-striped">
            <thead>
                <tr>
                    <th scope="col">Azon.</th>
                    <th scope="col" sortable="name" (sort)="onSort($event)">Név</th>
                    <th scope="col" sortable="type" (sort)="onSort($event)">Típus</th>
                    <th scope="col" sortable="accessString" (sort)="onSort($event)">Hozzáférési kód</th>
                    <th scope="col" (sort)="onSort($event)">Vezérlés</th>
                    <th scope="col"></th>
                </tr>
                <tr>
                    <th colspan="6">
                        <input
                            class="form-control ml-2 search-box"
                            type="text"
                            name="searchTerm"
                            placeholder="Keresés minden mezőben ..."
                            [(ngModel)]="controlService.searchTerm"
                        />
                    </th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let control of controlService.controls$ | async">
                    <th scope="row">{{ control.id }}</th>
                    <td>
                        <ngb-highlight [result]="control.name" [term]="controlService.searchTerm"></ngb-highlight>
                    </td>
                    <td>
                        <ngb-highlight [result]="control.type"></ngb-highlight>
                    </td>
                    <td>
                        <ngb-highlight
                            [result]="control.accessString"
                            [term]="controlService.searchTerm"
                        ></ngb-highlight>
                    </td>
                    <td>
                        <app-control-usage-dialog [control]="control"></app-control-usage-dialog>
                    </td>
                    <td>
                        <div class="edit-delete">
                            <app-new-control-dialog [isEdit]="true" [control]="control"></app-new-control-dialog>
                            <button type="button" (click)="delete(control.id)" class="btn btn-sm btn-danger delete">
                                <span>
                                    <fa-icon [icon]="faTrash"></fa-icon>
                                </span>
                            </button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>

        <div class="d-flex justify-content-between p-2">
            <ngb-pagination
                [collectionSize]="(controlService.total$ | async)!"
                [(page)]="controlService.page"
                [pageSize]="controlService.pageSize"
            >
            </ngb-pagination>

            <select class="custom-select" style="width: auto" name="pageSize" [(ngModel)]="controlService.pageSize">
                <option [value]="10">10 elem oldalanként</option>
                <option [value]="50">50 elem oldalanként</option>
                <option [value]="100">100 elem oldalanként</option>
            </select>
        </div>
    </form>
</div>
