import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { faSync, faTrash } from '@fortawesome/free-solid-svg-icons';
import { SortableHeader, SortEvent } from '../../directives/sortable-header/sortable-header.directive';
import { DeviceModel } from '../../models/device-model';
import { DeviceService } from '../../services/device/device.service';
import { LoaderService } from '../../services/loader/loader.service';

@Component({
    selector: 'app-device',
    templateUrl: './device.component.html',
    styleUrls: ['./device.component.css'],
})
export class DeviceComponent implements OnInit {
    faSync = faSync;
    faTrash = faTrash;

    @ViewChildren(SortableHeader)
    headers: QueryList<SortableHeader<DeviceModel>>;

    constructor(public deviceService: DeviceService, public loaderService: LoaderService) {}

    ngOnInit(): void {}

    onSort({ column, direction }: SortEvent<DeviceModel>) {
        // resetting other headers
        this.headers.forEach((header) => {
            if (header.sortable !== column) {
                header.direction = '';
            }
        });

        this.deviceService.sortColumn = column;
        this.deviceService.sortDirection = direction;
    }

    async delete(id: number) {
        await this.deviceService.delete(id);

        await this.refresh();
    }

    async refresh() {
        await this.deviceService.refresh();
    }
}
