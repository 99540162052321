import { Component, Input, OnInit } from '@angular/core';
import { faPencilAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ControlModel, ControlType } from '../../../models/control-model';
import { ControlService } from '../../../services/control/control.service';
import { LoaderService } from '../../../services/loader/loader.service';

@Component({
    selector: 'app-new-control-dialog',
    templateUrl: './new-control-dialog.component.html',
    styleUrls: ['./new-control-dialog.component.css'],
})
export class NewControlDialogComponent implements OnInit {
    closeResult = '';
    faPlus = faPlus;
    faPencilAlt = faPencilAlt;

    @Input()
    isEdit: boolean = false;

    @Input()
    control: ControlModel = {
        id: undefined,
        name: '',
        type: undefined,
        accessString: '',
        order: 0,
    };

    controlTypes = Object.keys(ControlType);

    constructor(
        private modalService: NgbModal,
        public controlService: ControlService,
        public loaderService: LoaderService,
    ) {}

    ngOnInit(): void {}

    open(content) {
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
            (result) => {
                this.closeResult = `Closed with: ${result}`;
            },
            (reason) => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            },
        );
    }

    private getDismissReason(reason: ModalDismissReasons): string {
        console.log(reason);
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    async save() {
        await this.controlService.save(this.control);

        this.modalService.dismissAll();
        await this.controlService.refresh();
    }
}
