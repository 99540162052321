import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, switchMap, tap } from 'rxjs/operators';
import { SortColumn, SortDirection } from '../../directives/sortable-header/sortable-header.directive';
import { PaginatedState } from '../../directives/sortable-header/paginated-state';
import { UserModel } from '../../models/user-model';
import { nameof } from '../../nameof/nameof.operator';
import { BackendBaseService } from '../backend-base/backend-base.service';

interface SearchResult {
    users: UserModel[];
    total: number;
}

interface LoggedInUserProfile {
    guid: string;
    email: string;
    firstName: string;
    lastName: string;
    profileImageUrl: string;
}

const serviceBasePath = '/user';

@Injectable({
    providedIn: 'root',
})
export class UserService extends BackendBaseService<UserModel> {
    private searchSubj = new Subject<void>();
    private users = new BehaviorSubject<UserModel[]>([]);
    private total = new BehaviorSubject<number>(0);

    loggedInUser = new BehaviorSubject<LoggedInUserProfile>(undefined);

    private state: PaginatedState<UserModel> = {
        page: 1,
        pageSize: 10,
        searchTerm: '',
        searchColumns: [nameof<UserModel>('firstName'), nameof<UserModel>('lastName'), nameof<UserModel>('email')],
        sortColumn: 'lastName',
        sortDirection: 'ASC',
    };

    constructor(injector: Injector) {
        super(injector, serviceBasePath);

        this.searchSubj
            .pipe(
                debounceTime(200),
                switchMap(() => this.search()),
            )
            .subscribe();

        this.search();
    }

    get users$() {
        return this.users.asObservable();
    }
    get total$() {
        return this.total.asObservable();
    }
    get page() {
        return this.state.page;
    }
    get pageSize() {
        return this.state.pageSize;
    }
    get searchTerm() {
        return this.state.searchTerm;
    }

    set page(page: number) {
        this.set({ page });
    }
    set pageSize(pageSize: number) {
        this.set({ pageSize });
    }
    set searchTerm(searchTerm: string) {
        this.set({ searchTerm });
    }
    set sortColumn(sortColumn: SortColumn<UserModel>) {
        this.set({ sortColumn });
    }
    set sortDirection(sortDirection: SortDirection) {
        this.set({ sortDirection });
    }

    async refresh(autoHideLoader = true) {
        await this.search(autoHideLoader);
    }

    private set(patch: Partial<PaginatedState<UserModel>>) {
        Object.assign(this.state, patch);
        this.searchSubj.next();
    }

    private async search(autoHideLoader = true): Promise<SearchResult> {
        const response = await this.paginatedList(this.state, [
            { key: 'join', value: 'devices' },
            { key: 'join', value: 'permissions' },
            { key: 'join', value: 'permissions.user' },
            { key: 'join', value: 'permissions.control' },
        ]);

        if (autoHideLoader) {
            this.loaderService.hide();
        }

        const result = {
            users: response?.data ?? [],
            total: response?.total ?? 0,
        };

        this.users.next(result.users);
        this.total.next(result.total);

        return result;
    }
}
