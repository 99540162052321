import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { PermissionModel } from '../../models/permission-model';
import { BackendBaseService } from '../backend-base/backend-base.service';
import * as _ from 'underscore';

const serviceBasePath = '/permission';

@Injectable({
    providedIn: 'root',
})
export class PermissionService extends BackendBaseService<PermissionModel> {
    private permissions = new BehaviorSubject<PermissionModel[]>([]);

    constructor(injector: Injector) {
        super(injector, serviceBasePath);
        this.refresh();
    }

    get permissions$() {
        return this.permissions.asObservable();
    }

    async refresh(autoHideLoader = true) {
        const data =
            (await super.list([
                { key: 'join', value: 'user' },
                { key: 'join', value: 'control' },
            ])) ?? [];

        if (autoHideLoader) {
            this.loaderService.hide();
        }

        this.permissions.next(_.sortBy(data, (x) => x.control.order));
    }
}
