import { Component, Input, OnInit } from '@angular/core';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { map } from 'rxjs/operators';
import { PermissionModel } from '../../../models/permission-model';
import { UserModel } from '../../../models/user-model';
import { LoaderService } from '../../../services/loader/loader.service';
import { PermissionService } from '../../../services/permission/permission.service';
import { UserService } from '../../../services/user/user.service';

@Component({
    selector: 'app-user-permission',
    templateUrl: './user-permission.component.html',
    styleUrls: ['./user-permission.component.css'],
})
export class UserPermissionComponent implements OnInit {
    faCheck = faCheck;
    faTimes = faTimes;

    @Input()
    user: UserModel;

    constructor(public permissionService: PermissionService, public loaderService: LoaderService) {}

    ngOnInit(): void {}

    getUserFilteredPermissions() {
        return this.permissionService.permissions$.pipe(map((p) => p.filter((pp) => pp.user.id === this.user.id)));
    }

    async toggleLocalAccessAllowed(permission: PermissionModel) {
        permission.localAccessAllowed = !permission.localAccessAllowed;
        await this.permissionService.save(permission);
        await this.refresh();
    }

    async toggleRemoteAccessAllowed(permission: PermissionModel) {
        permission.remoteAccessAllowed = !permission.remoteAccessAllowed;
        await this.permissionService.save(permission);
        await this.refresh();
    }

    async refresh() {
        await this.permissionService.refresh();
    }
}
