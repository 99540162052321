import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { SortableHeader, SortEvent } from '../../directives/sortable-header/sortable-header.directive';
import { HistoryModel } from '../../models/history-model';
import { HistoryService } from '../../services/history/history.service';
import { LoaderService } from '../../services/loader/loader.service';

@Component({
    selector: 'app-log',
    templateUrl: './history.component.html',
    styleUrls: ['./history.component.css'],
})
export class HistoryComponent implements OnInit {
    faSync = faSync;

    @ViewChildren(SortableHeader)
    headers: QueryList<SortableHeader<HistoryModel>>;

    constructor(public historyService: HistoryService, public loaderService: LoaderService) {}

    ngOnInit(): void {}

    onSort({ column, direction }: SortEvent<HistoryModel>) {
        // resetting other headers
        this.headers.forEach((header) => {
            if (header.sortable !== column) {
                header.direction = '';
            }
        });

        this.historyService.sortColumn = column;
        this.historyService.sortDirection = direction;
    }

    async refresh() {
        await this.historyService.refresh();
    }
}
