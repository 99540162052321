import { Component, Input, OnInit } from '@angular/core';
import { faPencilAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeviceModel } from '../../../models/device-model';
import { UserModel } from '../../../models/user-model';
import { DeviceService } from '../../../services/device/device.service';
import { LoaderService } from '../../../services/loader/loader.service';
import { UserService } from '../../../services/user/user.service';

@Component({
    selector: 'app-new-device-dialog',
    templateUrl: './new-device-dialog.component.html',
    styleUrls: ['./new-device-dialog.component.css'],
})
export class NewDeviceDialogComponent implements OnInit {
    closeResult = '';
    faPlus = faPlus;
    faPencilAlt = faPencilAlt;

    @Input()
    allowUserEdit: boolean = true;

    @Input()
    isEdit: boolean = false;

    @Input()
    user: UserModel;

    @Input()
    device: DeviceModel = {
        id: undefined,
        name: '',
        androidId: '',
        user: {},
    };

    constructor(
        private modalService: NgbModal,
        public deviceService: DeviceService,
        public loaderService: LoaderService,
        public userService: UserService,
    ) {}

    ngOnInit(): void {
        if (this.user) {
            this.device.user = this.user;
        }
    }

    open(content) {
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
            (result) => {
                this.closeResult = `Closed with: ${result}`;
            },
            (reason) => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            },
        );
    }

    private getDismissReason(reason: any): string {
        console.log(reason);
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    async save() {
        await this.deviceService.save({ ...this.device, user: { id: this.device.user.id } });

        this.modalService.dismissAll();
        await this.deviceService.refresh();
        await this.userService.refresh();
    }
}
