<div class="conainer">
    <form>
        <table class="table">
            <thead>
                <tr>
                    <th scope="col" colspan="3" class="title">Eszközök</th>
                </tr>
                <tr class="thead-light">
                    <th scope="col">Eszköz név</th>
                    <th scope="col">Android ID</th>
                    <th>
                        <app-new-device-dialog
                            [user]="user"
                            [allowUserEdit]="false"
                            class="new"
                        ></app-new-device-dialog>
                    </th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let device of getUserFilteredDevices()">
                    <td>{{ device.name }}</td>
                    <td>{{ device.androidId }}</td>
                    <td>
                        <div class="edit-delete">
                            <app-new-device-dialog
                                [isEdit]="true"
                                [user]="user"
                                [device]="device"
                            ></app-new-device-dialog>
                            <button type="button" (click)="delete(device.id)" class="btn btn-sm btn-danger delete">
                                <span>
                                    <fa-icon [icon]="faTrash"></fa-icon>
                                </span>
                            </button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </form>
</div>
