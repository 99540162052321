import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { faSync, faTrash } from '@fortawesome/free-solid-svg-icons';
import { SortableHeader, SortEvent } from '../../directives/sortable-header/sortable-header.directive';
import { ControlModel } from '../../models/control-model';
import { ControlService } from '../../services/control/control.service';
import { LoaderService } from '../../services/loader/loader.service';
import { UserService } from '../../services/user/user.service';

@Component({
    selector: 'app-control',
    templateUrl: './control.component.html',
    styleUrls: ['./control.component.css'],
})
export class ControlComponent implements OnInit {
    faSync = faSync;
    faTrash = faTrash;

    @ViewChildren(SortableHeader)
    headers: QueryList<SortableHeader<ControlModel>>;

    constructor(
        public controlService: ControlService,
        public userService: UserService,
        public loaderService: LoaderService,
    ) {}

    ngOnInit(): void {}

    onSort({ column, direction }: SortEvent<ControlModel>) {
        // resetting other headers
        this.headers.forEach((header) => {
            if (header.sortable !== column) {
                header.direction = '';
            }
        });

        this.controlService.sortColumn = column;
        this.controlService.sortDirection = direction;
    }

    async delete(id: number) {
        await this.controlService.delete(id);

        await this.refresh();
    }

    async refresh() {
        await this.controlService.refresh();
    }
}
