<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Új eszköz hozzáadás</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form (ngSubmit)="save()">
            <div class="form-group">
                <label for="userId">Felhasználó</label>
                <select
                    [(ngModel)]="device.user.id"
                    id="userId"
                    name="device.user.id"
                    class="form-control"
                    [disabled]="!allowUserEdit"
                >
                    <option *ngFor="let user of userService.users$ | async" [value]="user.id">
                        {{ user.lastName }} {{ user.firstName }}
                    </option>
                </select>
            </div>
            <div class="form-group">
                <label for="name">Név</label>
                <input [(ngModel)]="device.name" id="name" name="device.name" class="form-control" />
            </div>
            <div class="form-group">
                <label for="androidId">Android ID</label>
                <input [(ngModel)]="device.androidId" id="androidId" name="device.androidId" class="form-control" />
            </div>
            <button type="submit" class="btn btn-success save">Mentés</button>
        </form>
    </div>
</ng-template>

<button class="btn btn-sm btn-success" (click)="open(content)">
    <fa-icon *ngIf="isEdit" [icon]="faPencilAlt"></fa-icon>
    <fa-icon *ngIf="!isEdit" [icon]="faPlus"></fa-icon>
    <span *ngIf="!isEdit"> Új eszköz</span>
</button>
