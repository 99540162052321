<div class="container">
    <div class="inner-container">
        <img src="assets/img/not-found.png" />
        <br /><br />

        <h3>Ez az oldal sajnos nem található!</h3>
        <br />

        <a id="back-to-home" (click)="backToHome()">vissza a főoldalra</a>
    </div>
</div>
