<div class="conainer">
    <form>
        <button type="button" id="refresh" (click)="refresh()" class="btn btn-sm btn-primary">
            <fa-icon [icon]="faSync"></fa-icon>
            Frissítés
        </button>

        <table class="table table-striped">
            <thead>
                <tr>
                    <th scope="col">Azon.</th>
                    <th scope="col" sortable="controlName" (sort)="onSort($event)">Vezérlő név</th>
                    <th scope="col" sortable="actionName" (sort)="onSort($event)">Művelet</th>
                    <th scope="col" sortable="deviceName" (sort)="onSort($event)">Eszköz név</th>
                    <th scope="col" sortable="deviceAndroidId" (sort)="onSort($event)">Eszköz android ID</th>
                    <th scope="col" sortable="userLastName" (sort)="onSort($event)">Felhasználó név</th>
                    <th scope="col" sortable="createdAt" (sort)="onSort($event)">Dátum</th>
                </tr>
                <tr>
                    <th colspan="7">
                        <input
                            class="form-control ml-2 search-box"
                            type="text"
                            name="searchTerm"
                            placeholder="Keresés minden mezőben ..."
                            [(ngModel)]="historyService.searchTerm"
                        />
                    </th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let history of historyService.histories$ | async">
                    <th scope="row">{{ history.id }}</th>
                    <td>
                        <ngb-highlight
                            [result]="history.controlName"
                            [term]="historyService.searchTerm"
                        ></ngb-highlight>
                    </td>
                    <td>
                        <ngb-highlight [result]="history.actionName" [term]="historyService.searchTerm"></ngb-highlight>
                    </td>
                    <td>
                        <ngb-highlight [result]="history.deviceName" [term]="historyService.searchTerm"></ngb-highlight>
                    </td>
                    <td>
                        <ngb-highlight
                            [result]="history.deviceAndroidId"
                            [term]="historyService.searchTerm"
                        ></ngb-highlight>
                    </td>
                    <td>
                        <ngb-highlight
                            [result]="history.userLastName + ' ' + history.userFirstName"
                            [term]="historyService.searchTerm"
                        ></ngb-highlight>
                    </td>
                    <td>
                        <ngb-highlight
                            [result]="history.createdAt | date: 'YYYY-MM-dd HH:mm:ss'"
                            [term]="historyService.searchTerm"
                        ></ngb-highlight>
                    </td>
                </tr>
            </tbody>
        </table>

        <div class="d-flex justify-content-between p-2">
            <ngb-pagination
                [collectionSize]="(historyService.total$ | async)!"
                [(page)]="historyService.page"
                [pageSize]="historyService.pageSize"
            >
            </ngb-pagination>

            <select class="custom-select" style="width: auto" name="pageSize" [(ngModel)]="historyService.pageSize">
                <option [value]="10">10 elem oldalanként</option>
                <option [value]="50">50 elem oldalanként</option>
                <option [value]="100">100 elem oldalanként</option>
            </select>
        </div>
    </form>
</div>
