<div class="conainer">
    <form>
        <table class="table">
            <thead>
                <tr>
                    <th scope="col" colspan="3" class="title">Jogosultságok</th>
                </tr>
                <tr class="thead-light">
                    <th scope="col">Vezérlő név</th>
                    <th scope="col">Helyi hozzáférés</th>
                    <th scope="col">Távoli hozzáférés</th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let permission of getUserFilteredPermissions() | async">
                    <td>{{ permission.control.name }}</td>

                    <td class="local-access-allowed">
                        <button
                            type="button"
                            (click)="toggleLocalAccessAllowed(permission)"
                            class="btn btn-sm"
                            [class.btn-success]="permission.localAccessAllowed"
                            [class.btn-danger]="!permission.localAccessAllowed"
                        >
                            <div *ngIf="permission.localAccessAllowed; else noLocalAccessAllowed">
                                <span>
                                    <fa-icon [icon]="faCheck"></fa-icon>
                                </span>
                            </div>
                            <ng-template #noLocalAccessAllowed>
                                <span>
                                    <fa-icon [icon]="faTimes"></fa-icon>
                                </span>
                            </ng-template>
                        </button>
                    </td>
                    <td class="remote-access-allowed">
                        <button
                            type="button"
                            (click)="toggleRemoteAccessAllowed(permission)"
                            class="btn btn-sm"
                            [class.btn-success]="permission.remoteAccessAllowed"
                            [class.btn-danger]="!permission.remoteAccessAllowed"
                        >
                            <div *ngIf="permission.remoteAccessAllowed; else noRemoteAccessAllowed">
                                <span>
                                    <fa-icon [icon]="faCheck"></fa-icon>
                                </span>
                            </div>
                            <ng-template #noRemoteAccessAllowed>
                                <span>
                                    <fa-icon [icon]="faTimes"></fa-icon>
                                </span>
                            </ng-template>
                        </button>
                    </td>
                    <!-- <td>
                        <div class="edit-delete">
                            <app-new-device-dialog
                                [isEdit]="true"
                                [user]="user"
                                [device]="device"
                            ></app-new-device-dialog>
                            <button type="button" (click)="delete(device.id)" class="btn btn-sm btn-danger delete">
                                <span>
                                    <fa-icon [icon]="faTrash"></fa-icon>
                                </span>
                            </button>
                        </div>
                    </td> -->
                </tr>
            </tbody>
        </table>
    </form>
</div>
