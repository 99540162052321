<nav class="navbar navbar-expand-lg navbar-dark bg-dark navbar-header">
    <a href="" class="navbar-brand">Otthon vezérlés admin</a>
    <!-- for responsivity -->
    <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNavAltMarkup"
        aria-controls="navbarNavAltMarkup"
        aria-expanded="false"
        aria-label="Toggle navigation"
    >
        <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
        <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
            <li routerLink="/user" routerLinkActive="active"><a>Felhasználók</a></li>
            <li routerLink="/device" routerLinkActive="active"><a>Eszközök</a></li>
            <li routerLink="/control" routerLinkActive="active"><a>Vezérlők</a></li>
            <li routerLink="/history" routerLinkActive="active"><a>Napló</a></li>
        </ul>
        <ul class="navbar-nav">
            <div id="nav-profile">
                <a id="logout" (click)="logout()">Kilépés</a>
                <br />
                <span id="logged-in-user-email">{{ loggedInUserEmail$ | async }}</span>
            </div>
        </ul>
    </div>
</nav>

<router-outlet></router-outlet>
