<div class="conainer">
    <form>
        <app-new-user-dialog id="new"></app-new-user-dialog>
        <button type="button" id="refresh" (click)="refresh()" class="btn btn-sm btn-primary">
            <fa-icon [icon]="faSync"></fa-icon>
            Frissítés
        </button>

        <table class="table table-striped">
            <thead>
                <tr>
                    <th scope="col"></th>
                    <th scope="col">Azon.</th>
                    <th scope="col" sortable="lastName" (sort)="onSort($event)">Név</th>
                    <th scope="col" sortable="email" (sort)="onSort($event)">Email</th>
                    <th scope="col" sortable="guid" (sort)="onSort($event)">GUID</th>
                    <th scope="col"></th>
                </tr>
                <tr>
                    <th colspan="6">
                        <input
                            class="form-control ml-2 search-box"
                            type="text"
                            name="searchTerm"
                            placeholder="Keresés minden mezőben ..."
                            [(ngModel)]="userService.searchTerm"
                        />
                    </th>
                </tr>
            </thead>

            <tbody>
                <ng-container *ngFor="let user of userService.users$ | async; let i = index">
                    <tr
                        data-toggle="collapse"
                        [attr.data-target]="'#details' + i"
                        class="accordion-toggle"
                        (click)="rowClick(i)"
                    >
                        <th scope="row" class="chevron">
                            <fa-icon
                                id="{{ 'chevronRight' + i }}"
                                style="display: inline"
                                [icon]="faChevronRight"
                            ></fa-icon>
                            <fa-icon
                                id="{{ 'chevronDown' + i }}"
                                style="display: none"
                                [icon]="faChevronDown"
                            ></fa-icon>
                        </th>
                        <th scope="row">{{ user.id }}</th>
                        <td>
                            <ngb-highlight
                                [result]="user.lastName + ' ' + user.firstName"
                                [term]="userService.searchTerm"
                            ></ngb-highlight>
                        </td>
                        <td>
                            <ngb-highlight [result]="user.email" [term]="userService.searchTerm"></ngb-highlight>
                        </td>
                        <td>
                            <ngb-highlight [result]="user.guid" [term]="userService.searchTerm"></ngb-highlight>
                        </td>
                        <td>
                            <div class="edit-delete">
                                <app-new-user-dialog [isEdit]="true" [user]="user"></app-new-user-dialog>
                                <button
                                    type="button"
                                    (click)="delete($event, user.id)"
                                    class="btn btn-sm btn-danger delete"
                                >
                                    <span>
                                        <fa-icon [icon]="faTrash"></fa-icon>
                                    </span>
                                </button>
                            </div>
                        </td>
                    </tr>
                    <tr></tr>
                    <tr>
                        <td colspan="4" class="hidden-row details-row">
                            <div id="{{ 'details' + i }}" class="accordian-body collapse">
                                <app-user-device [user]="user" class="devices"></app-user-device>
                            </div>
                        </td>
                        <td colspan="4" class="hidden-row details-row">
                            <div id="{{ 'details' + i }}" class="accordian-body collapse">
                                <app-user-permission [user]="user" class="permissions"></app-user-permission>
                            </div>
                        </td>
                        <td class="hidden-row"></td>
                    </tr>
                </ng-container>
            </tbody>
        </table>

        <div class="d-flex justify-content-between p-2">
            <ngb-pagination
                [collectionSize]="(userService.total$ | async)!"
                [(page)]="userService.page"
                [pageSize]="userService.pageSize"
            >
            </ngb-pagination>

            <select class="custom-select" style="width: auto" name="pageSize" [(ngModel)]="userService.pageSize">
                <option [value]="10">10 elem oldalanként</option>
                <option [value]="50">50 elem oldalanként</option>
                <option [value]="100">100 elem oldalanként</option>
            </select>
        </div>
    </form>
</div>
