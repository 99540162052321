import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { LoginCallbackComponent } from './components/login-callback/login-callback.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { DeviceComponent } from './components/device/device.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { UserComponent } from './components/user/user.component';
import { ControlComponent } from './components/control/control.component';
import { HistoryComponent } from './components/history/history.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';

export const enum RouteValues {
    HOME = '',
    LOGIN = 'login',
    LOGIN_GOOGLE_CALLBACK = 'login/google/callback',
    DEVICE = 'device',
    USER = 'user',
    CONTROL = 'control',
    HISTORY = 'history',
    PRIVACY_POLICY = 'privacy-policy',
}

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: RouteValues.DEVICE,
    },
    {
        path: RouteValues.LOGIN,
        component: LoginComponent,
    },
    {
        path: RouteValues.PRIVACY_POLICY,
        component: PrivacyPolicyComponent,
    },
    {
        path: RouteValues.LOGIN_GOOGLE_CALLBACK,
        component: LoginCallbackComponent,
    },
    {
        path: '',
        component: NavbarComponent,
        children: [
            {
                path: RouteValues.DEVICE,
                component: DeviceComponent,
            },
            {
                path: RouteValues.USER,
                component: UserComponent,
            },
            {
                path: RouteValues.CONTROL,
                component: ControlComponent,
            },
            {
                path: RouteValues.HISTORY,
                component: HistoryComponent,
            },
            {
                path: '**',
                component: NotFoundComponent,
            }, // Wildcard route for a 404 page
        ],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
