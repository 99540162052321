import { Component, Input, OnInit } from '@angular/core';
import { faSync, faTrash } from '@fortawesome/free-solid-svg-icons';
import { DeviceService } from '../../../services/device/device.service';
import { LoaderService } from '../../../services/loader/loader.service';
import { UserModel } from '../../../models/user-model';
import * as _ from 'underscore';
import { UserService } from '../../../services/user/user.service';

@Component({
    selector: 'app-user-device',
    templateUrl: './user-device.component.html',
    styleUrls: ['./user-device.component.css'],
})
export class UserDeviceComponent implements OnInit {
    faSync = faSync;
    faTrash = faTrash;

    @Input()
    user: UserModel;

    constructor(
        public deviceService: DeviceService,
        public userService: UserService,
        public loaderService: LoaderService,
    ) {}

    ngOnInit(): void {}

    getUserFilteredDevices() {
        return this.user.devices;
    }

    async delete(id: number) {
        await this.deviceService.delete(id);
        await this.refresh();
    }

    async refresh() {
        await this.userService.refresh();
    }
}
